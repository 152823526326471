import { DynamicFeed } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import GroupsIcon from '@mui/icons-material/Groups';
import HouseIcon from '@mui/icons-material/House';
import MapIcon from '@mui/icons-material/Map';
import MessageIcon from '@mui/icons-material/Message';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import TopicIcon from '@mui/icons-material/Topic';
import WorkIcon from '@mui/icons-material/Work';
import React from 'react';
import {
  AdminUI,
  AdminUIProps,
  CustomRoutes,
  Resource,
  ResourceContextProvider,
} from 'react-admin';
import { Outlet, Route } from 'react-router-dom';
import { useDashboardVisibility } from '../../hooks/useDashboardVisibility';
import { useFeatureFlags } from '../FeatureFlagGuard';
import { BadgesAnalytics } from './Badges/BadgesAnalytics';
import { BadgesCreate } from './Badges/BadgesCreate';
import { BadgesEdit } from './Badges/BadgesEdit';
import { BadgesList } from './Badges/BadgesList';
import { SingleBadgeAnalytics } from './Badges/SingleBadgeAnalytics';
import { CohortList } from './Cohort/CohortList';
import { CohortMembershipList } from './Cohort/CohortMembership/CohortMembershipList';
import { DepartmentCreate } from './Department/DepartmentCreate';
import { DepartmentEdit } from './Department/DepartmentEdit';
import { DepartmentList } from './Department/DepartmentList';
import { GroupsCustom } from './Chat/GroupsCustom';
import { JobTitleCreate } from './JobTitle/JobTitleCreate';
import { JobTitleEdit } from './JobTitle/JobTitleEdit';
import { JobTitleList } from './JobTitle/JobTitleList';
import { NudgeCategoryCreate } from './NudgeCategory/NudgeCategoryCreate';
import { NudgeCategoryEdit } from './NudgeCategory/NudgeCategoryEdit';
import { NudgeCategoryList } from './NudgeCategory/NudgeCategoryList';
import { NudgeCreate } from './Nudges/NudgeCreate';
import { NudgeEdit } from './Nudges/NudgeEdit';
import { NudgeFeedbackList } from './Nudges/NudgeFeedback/NudgeFeedbackList';
import { NudgeList } from './Nudges/NudgeList';
import { NudgeSlideCreate } from './Nudges/NudgeSlide/NudgeSlideCreate';
import { NudgeSlideEdit } from './Nudges/NudgeSlide/NudgeSlideEdit';
import { NudgeSlideList } from './Nudges/NudgeSlide/NudgeSlideList';
import { PostCreate } from './Posts/PostCreate';
import { PostList } from './Posts/PostList';
import { RegionCreate } from './Region/RegionCreate';
import { RegionEdit } from './Region/RegionEdit';
import { RegionList } from './Region/RegionList';
import { SmsMessageList } from './SmsMessages/SmsMessageList';
import { TopicCreate } from './Topic/TopicCreate';
import { TopicEdit } from './Topic/TopicEdit';
import { TopicList } from './Topic/TopicList';
import { UnitCreate } from './Unit/UnitCreate';
import { UnitEdit } from './Unit/UnitEdit';
import { UnitList } from './Unit/UnitList';
import { UserEdit } from './User/UserEdit';
import { UserList } from './User/UserList';
import { CohortCreate } from './Cohort/CohortCreate';
import { CohortEdit } from './Cohort/CohortEdit';
import { UsersAnalytics } from './Dashboard/Users/Analytics';
import { PulseChecksChart } from './Dashboard/PulseChecks/Chart';
import { FeedAnalytics } from './Dashboard/Feed/Analytics';

export enum ResourcesValue {
  POSTS = 'posts',
  USERS = 'users',
  SMS = 'sms',
  DEPARTMENTS = 'departments',
  JOB_TITLES = 'jobTitles',
  REGIONS = 'regions',
  UNITS = 'units',
  TOPICS = 'topics',
  NUDGES = 'nudges',
  NUDGE_CATEGORY = 'nudgeCategories',
  NUDGE_SLIDES = 'nudgeSlides',
  COHORTS = 'cohorts',
  COHORT_MEMBERSHIPS = 'cohortMemberships',
  BADGES = 'badges',
  BADGES_AWARDS = 'badgesAwards',
  BADGE_AWARDS = 'badgeAwards',
}

export enum CustomResourcesValue {
  CHAT = 'chat',
  DASHBOARD_USERS = 'dashboard/users',
  DASHBOARD_FEED = 'dashboard/feed',
  DASHBOARD_PULSE_CHECKS = 'dashboard/pulseChecks',
}

export const AdminResources: React.FC<AdminUIProps> = (props) => {
  const { data: featureFlags } = useFeatureFlags();
  const { showDashboard, isAdmin } = useDashboardVisibility();

  return (
    <AdminUI {...props}>
      <Resource
        name={ResourcesValue.POSTS}
        icon={DynamicFeed}
        list={PostList}
        create={PostCreate}
      />
      <CustomRoutes>
        {showDashboard && (
          <>
            <Route
              path={`/${CustomResourcesValue.DASHBOARD_USERS}`}
              element={<UsersAnalytics />}
            />
            <Route
              path={`/${CustomResourcesValue.DASHBOARD_FEED}`}
              element={<FeedAnalytics />}
            />
            <Route
              path={`/${CustomResourcesValue.DASHBOARD_PULSE_CHECKS}`}
              element={<PulseChecksChart />}
            />
          </>
        )}
        <Route
          path={`/${CustomResourcesValue.CHAT}`}
          element={<GroupsCustom />}
        />
      </CustomRoutes>
      {isAdmin ? (
        <>
          <Resource
            name={ResourcesValue.USERS}
            icon={PeopleIcon}
            list={UserList}
            edit={UserEdit}
          />
          <Resource
            name={ResourcesValue.SMS}
            options={{ label: 'Welcome SMS Status' }}
            icon={MessageIcon}
            list={SmsMessageList}
          />
          <Resource
            name={ResourcesValue.DEPARTMENTS}
            icon={BusinessIcon}
            list={DepartmentList}
            edit={DepartmentEdit}
            create={DepartmentCreate}
          />
          <Resource
            name={ResourcesValue.JOB_TITLES}
            icon={WorkIcon}
            list={JobTitleList}
            edit={JobTitleEdit}
            create={JobTitleCreate}
          />
          <Resource
            name={ResourcesValue.REGIONS}
            icon={MapIcon}
            list={RegionList}
            edit={RegionEdit}
            create={RegionCreate}
          />
          <Resource
            name={ResourcesValue.UNITS}
            icon={HouseIcon}
            list={UnitList}
            edit={UnitEdit}
            create={UnitCreate}
          />
          <Resource
            name={ResourcesValue.TOPICS}
            icon={TopicIcon}
            list={TopicList}
            edit={TopicEdit}
            create={TopicCreate}
          />
          {featureFlags?.withNudges ? (
            <>
              <Resource
                name={ResourcesValue.NUDGES}
                icon={SchoolIcon}
                list={NudgeList}
                create={NudgeCreate}
                edit={NudgeEdit}
              >
                <Route
                  path=":nudgeId/slides"
                  element={
                    <ResourceContextProvider value="nudgeSlides">
                      <Outlet />
                    </ResourceContextProvider>
                  }
                >
                  <Route path="" element={<NudgeSlideList />} />
                  <Route path="create" element={<NudgeSlideCreate />} />
                  <Route path=":id" element={<NudgeSlideEdit />} />
                </Route>
                <Route
                  path=":nudgeId/feedbacks"
                  element={
                    <ResourceContextProvider value="nudgeFeedbacks">
                      <NudgeFeedbackList />
                    </ResourceContextProvider>
                  }
                />
              </Resource>
              <Resource
                name={ResourcesValue.NUDGE_CATEGORY}
                icon={CategoryIcon}
                list={NudgeCategoryList}
                create={NudgeCategoryCreate}
                edit={NudgeCategoryEdit}
              />
              <Resource
                name={ResourcesValue.COHORTS}
                icon={GroupsIcon}
                list={CohortList}
                create={CohortCreate}
                edit={CohortEdit}
              >
                <Route
                  path=":cohortId/members"
                  element={
                    <ResourceContextProvider
                      value={ResourcesValue.COHORT_MEMBERSHIPS}
                    >
                      <CohortMembershipList displaying="members" />
                    </ResourceContextProvider>
                  }
                />
                <Route
                  path=":cohortId/members/add"
                  element={
                    <ResourceContextProvider
                      value={ResourcesValue.COHORT_MEMBERSHIPS}
                    >
                      <CohortMembershipList displaying="non-members" />
                    </ResourceContextProvider>
                  }
                />
              </Resource>
            </>
          ) : undefined}
          <Resource
            name={ResourcesValue.BADGES}
            icon={MilitaryTechIcon}
            list={BadgesList}
            edit={BadgesEdit}
            create={BadgesCreate}
          />
          <Resource
            name={ResourcesValue.BADGES_AWARDS}
            options={{ label: 'Awarded Badges' }}
          >
            <Route path="/analytics" element={<BadgesAnalytics />} />
          </Resource>
          <Resource
            name={ResourcesValue.BADGE_AWARDS}
            options={{ label: 'Awarded Badges' }}
          >
            <Route path="/analytics/:id" element={<SingleBadgeAnalytics />} />
          </Resource>
        </>
      ) : undefined}
    </AdminUI>
  );
};
