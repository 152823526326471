import React, { useState, useMemo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  useTheme,
  Box,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { sortBy } from '../../../../utils/sortBy';
import { format } from 'date-fns';
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Legend,
  Line,
  Tooltip,
} from 'recharts';
import {
  Entry,
  usePostsChartData,
} from '../../../../api/hooks/usePostsChartData';
import { DateRangeFilter } from '../DateRangeFilter';

interface ChartData {
  date: string;
  posts: number;
  reactions: number;
  comments: number;
}

interface FeedChartProps {
  after: string;
  before: string;
  setAfter: (date: string) => void;
  setBefore: (date: string) => void;
}

export const FeedChart: React.FC<FeedChartProps> = ({
  after,
  before,
  setAfter,
  setBefore,
}) => {
  const {
    moonstar: { charts },
  } = useTheme();

  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);

  const [showPosts, setShowPosts] = useState(true);
  const [showReactions, setShowReactions] = useState(true);
  const [showComments, setShowComments] = useState(true);

  const { data: chartData, refetch } = usePostsChartData({
    after: new Date(after).toISOString(),
    before: new Date(before).toISOString(),
  });

  const aggregatedData = useMemo(() => {
    const data: Record<string, ChartData> = {};

    const aggregateEntries = (
      entries?: Entry[],
      key: 'posts' | 'reactions' | 'comments' = 'posts'
    ) => {
      entries?.forEach((entry) => {
        const date = format(new Date(entry.createdOn), 'yyyy-MM-dd');

        if (!Object.prototype.hasOwnProperty.call(data, date)) {
          data[date] = { date, posts: 0, reactions: 0, comments: 0 };
        }

        data[date][key] += 1;
      });
    };

    if (chartData) {
      aggregateEntries(chartData.Posts, 'posts');
      aggregateEntries(chartData.Reactions, 'reactions');
      aggregateEntries(chartData.Comments, 'comments');
    }

    return data;
  }, [chartData]);

  const formattedData = Object.values(aggregatedData);
  const sortedData = sortBy(formattedData, (x) => x.date);

  return (
    <>
      <Card sx={{ boxShadow: 3 }}>
        <CardHeader
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', fontSize: '1rem', margin: 0 }}
              >
                Feed
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showPosts}
                        onChange={(e) => setShowPosts(e.target.checked)}
                        color="primary"
                        sx={{ transform: 'scale(0.7)' }}
                      />
                    }
                    label={<Typography variant="body2">Posts</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showComments}
                        onChange={(e) => setShowComments(e.target.checked)}
                        color="primary"
                        sx={{ transform: 'scale(0.7)' }}
                      />
                    }
                    label={<Typography variant="body2">Comments</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showReactions}
                        onChange={(e) => setShowReactions(e.target.checked)}
                        color="primary"
                        sx={{ transform: 'scale(0.7)' }}
                      />
                    }
                    label={<Typography variant="body2">Reactions</Typography>}
                  />
                </Box>
                <DateRangeFilter
                  startDate={after}
                  endDate={before}
                  setStartDate={setAfter}
                  setEndDate={setBefore}
                  onDateChange={() => void refetch()}
                />
              </Box>
            </Box>
          }
        />

        <CardContent
          sx={{
            padding: 1,
            paddingTop: 0,
          }}
        >
          <ResponsiveContainer width="100%" height={350}>
            <LineChart
              data={sortedData}
              margin={{ top: 5, right: 20, left: 10, bottom: 50 }}
            >
              <XAxis
                dataKey="date"
                tickFormatter={(date) => format(new Date(date), 'EEE dd/MM')}
                tick={{ fontSize: 12, stroke: 'rgb(84, 79, 90)' }}
                strokeWidth={0.5}
                angle={-45}
                textAnchor="end"
              />
              <YAxis
                yAxisId="left"
                orientation="left"
                stroke={charts.palette.color1}
                tickFormatter={(value) => Math.round(value).toString()}
                tick={{ fontSize: 12, stroke: charts.palette.color1 }}
                strokeWidth={0.5}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                tickFormatter={(value) => Math.round(value).toString()}
                tick={{ fontSize: 12 }}
                stroke="oklch(0.4 1 100)"
              />
              <Tooltip
                contentStyle={{
                  fontSize: '0.85rem',
                  backgroundColor: '#f4f4f4',
                  border: '1px solid #ccc',
                }}
              />
              <Legend
                verticalAlign="bottom"
                wrapperStyle={{
                  fontSize: '0.85rem',
                  paddingTop: 0,
                  marginBottom: -40,
                }}
                iconType="plainline"
              />
              <Line
                yAxisId="left"
                type="linear"
                dataKey="posts"
                name="Posts"
                stroke="oklch(0.4 0.2 211)"
                strokeWidth={2}
                dot={false}
                hide={!showPosts}
              />

              <Line
                yAxisId="left"
                type="linear"
                dataKey="comments"
                name="Comments"
                stroke="oklch(0.4 0.4 0)"
                strokeWidth={2}
                dot={false}
                hide={!showComments}
              />

              <Line
                yAxisId="right"
                type="linear"
                dataKey="reactions"
                name="Reactions"
                stroke="oklch(0.4 1 100)"
                strokeWidth={2}
                dot={false}
                hide={!showReactions}
              />
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </>
  );
};
