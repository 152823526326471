import { FC } from 'react';
import { FilterButton, TopToolbar } from 'react-admin';
import { ImportUsersButton } from './ImportUsersButton';
import { ExportUserTemplateButton } from './ExportUserTemplateButton';
import { BulkDisableUsersButton } from './BulkDisableUsersButton';

export const UserToolbarActions: FC = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <BulkDisableUsersButton />
      <ImportUsersButton />
      <ExportUserTemplateButton />
    </TopToolbar>
  );
};
