import { MenuItem, Typography } from '@mui/material';
import { Role } from '@sendbird/chat';
import type { Member } from '@sendbird/chat/groupChannel';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import { useLocalization } from '@sendbird/uikit-react/hooks/useLocalization';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@sendbird/uikit-react/ui/Button';
import UserListItem, {
  UserListItemProps,
} from '@sendbird/uikit-react/ui/UserListItem';
import UserListItemMenu from '@sendbird/uikit-react/ui/UserListItemMenu';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';
import { useRemoveUser } from '../../../../api/hooks/sendbird/useRemoveUser';
import { InviteUsersModal } from './InviteUsersModal';
import { MembersModal } from './MembersModal';

interface MemberListProps {
  canInviteUsers: boolean;
  renderUserListItem?: (
    props: UserListItemProps & { index: number }
  ) => ReactNode;
}
export const MemberList = ({
  canInviteUsers,
  renderUserListItem: RenderUserListItem = UserListItem,
}: MemberListProps): ReactElement => {
  const [members, setMembers] = useState<Array<Member>>([]);
  const [hasNext, setHasNext] = useState(false);
  const [showAllMembers, setShowAllMembers] = useState(false);
  const [showInviteUsers, setShowInviteUsers] = useState(false);
  const { channel, forceUpdateUI } = useChannelSettingsContext();
  const { stringSet } = useLocalization();
  const translate = useTranslate();

  const handleUpdateMembers = (id: string) =>
    setMembers(members.filter((member) => member.userId !== id));

  const { mutate: removeUser } = useRemoveUser({
    onSuccess: (_data, variables) => handleUpdateMembers(variables.id),
  });

  const refreshList = useCallback(() => {
    if (!channel) {
      setMembers([]);
      return;
    }
    const memberUserListQuery = channel.createMemberListQuery({
      // Short list of users. If the user wants to see more, they can click the "Show all" button, which presents a modal
      limit: 10,
    });
    void memberUserListQuery.next().then((members) => {
      setMembers(members);
      setHasNext(memberUserListQuery.hasNext);
    });
  }, [channel]);

  useEffect(refreshList, [channel?.url, refreshList]);

  return (
    <div className="sendbird-channel-settings-member-list">
      {members.map((member, index) => (
        <React.Fragment key={member.userId}>
          <RenderUserListItem
            index={index}
            user={member}
            channel={channel ?? undefined}
            size="small"
            avatarSize="24px"
            renderListItemMenu={(props) => (
              <UserListItemMenu
                {...props}
                onToggleOperatorState={({ newStatus: isOperator }) => {
                  const newMembers = [...members];
                  for (const newMember of newMembers) {
                    if (newMember.userId === member.userId) {
                      newMember.role = isOperator ? Role.OPERATOR : Role.NONE;
                      break;
                    }
                  }
                  setMembers(newMembers);
                }}
                onToggleMuteState={({ newStatus: isMuted }) => {
                  const newMembers = [...members];
                  for (const newMember of newMembers) {
                    if (newMember.userId === member.userId) {
                      newMember.isMuted = isMuted;
                      break;
                    }
                  }
                  setMembers(newMembers);
                }}
                onToggleBanState={() => handleUpdateMembers(member.userId)}
                renderMenuItems={({ items }) => {
                  const {
                    OperatorToggleMenuItem,
                    MuteToggleMenuItem,
                    BanToggleMenuItem,
                  } = items;

                  return (
                    <>
                      <OperatorToggleMenuItem />
                      <MuteToggleMenuItem />
                      <BanToggleMenuItem />

                      {channel?.myRole === Role.OPERATOR && (
                        <MenuItem
                          disableRipple
                          onClick={() =>
                            removeUser({ id: member.userId, channel })
                          }
                        >
                          <Typography variant="subtitle2" fontFamily="Roboto">
                            {translate('chat.kick')}
                          </Typography>
                        </MenuItem>
                      )}
                    </>
                  );
                }}
              />
            )}
          />
        </React.Fragment>
      ))}
      <div className="sendbird-channel-settings-accordion__footer">
        {hasNext && (
          <Button
            type={ButtonTypes.SECONDARY}
            size={ButtonSizes.SMALL}
            onClick={() => setShowAllMembers(true)}
          >
            <Typography variant="body2" fontFamily="Roboto">
              {stringSet.CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS}
            </Typography>
          </Button>
        )}
        {canInviteUsers && (
          <Button
            type={ButtonTypes.SECONDARY}
            size={ButtonSizes.SMALL}
            onClick={() => setShowInviteUsers(true)}
          >
            <Typography variant="body2" fontFamily="Roboto">
              {stringSet.CHANNEL_SETTING__MEMBERS__INVITE_MEMBER}
            </Typography>
          </Button>
        )}
      </div>
      {showAllMembers && (
        <MembersModal
          onCancel={() => {
            setShowAllMembers(false);
            refreshList();
            forceUpdateUI();
          }}
          renderUserListItem={RenderUserListItem}
        />
      )}
      <InviteUsersModal
        open={showInviteUsers}
        onCancel={() => setShowInviteUsers(false)}
        onSubmit={() => {
          setShowInviteUsers(false);
          refreshList();
          forceUpdateUI();
        }}
      />
    </div>
  );
};
