import { isValid, parse, format } from 'date-fns';
import { z } from 'zod';
import { AdminDataProvider } from '../../../api/adminDataProvider';

export interface User {
  id: number;
  pictureUrl: string;
  firstName: string;
  lastName: string;
  displayName: string;
  phoneNumber: string | null;
  email: string | null;
  jobTitleId: number;
  departmentId: number;
  regionId: number | null;
  unitsIds: number[];
  disabledFrom: string | null;
  role: string | null;
}

export type UserCsvRow = {
  internalId?: string;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
  startDate: string;
  department: string;
  jobTitle: string;
  region: string;
  unit: string;
  languageCountryCode: string;
};

export type UserCsvExportRow = UserCsvRow & {
  errorMessage: string;
};

export type UserCsvResultRow = UserCsvRow & {
  status: 'pending' | 'processing' | 'success' | 'error';
};

export type UserCsvValidationError = {
  property: string;
  message: string;
};

export interface UserLite {
  id: number;
  firstName: string;
  lastName: string;
}

const parseDate = (value: string, formats: string[]): string | null => {
  for (const f of formats) {
    const date = parse(value, f, new Date());
    if (isValid(date)) {
      return format(date, "yyyy-MM-dd'T'00:00:00.000'Z'");
    }
  }
  return null;
};

const dateSchema = z.string().transform((dateStr, ctx) => {
  const formats = ['dd/MM/yyyy', 'dd-MM-yyyy', 'dd-MMM-yyyy'];
  const date = parseDate(dateStr, formats);
  if (!date) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'Invalid date format. Please use one of the following formats: dd/MM/yyyy, dd-MM-yyyy or dd-MMM-yyyy',
    });
  }
  return date;
});

export const userSchema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  email: z
    .string()
    .trim()
    .transform((str) => (str ? str : undefined))
    .optional(),
  phoneNumber: z
    .string()
    .regex(
      /^\+\d{1,15}$/,
      'Phone number must be in format: +[countryCode][number]'
    ),
  startDate: dateSchema,
  department: z.string().min(1, { message: 'Department is required' }),
  jobTitle: z.string().min(1, { message: 'Job title is required' }),
  region: z.string().min(1, { message: 'Region is required' }),
  unit: z.string().min(1, { message: 'Unit is required' }),
  languageCountryCode: z.enum(['en', 'ro'], {
    errorMap: () => ({ message: "Allowed languages: 'en' or 'ro'" }),
  }),
});

export interface DisableUserRow {
  id: string;
  internalId: string;
  firstName: string;
  lastName: string;
}

export type DisableUserResultRow = DisableUserRow & {
  status: 'pending' | 'processing' | 'success' | 'error';
};

export type DisableUserExportRow = DisableUserRow & {
  errorMessage: string;
};

export const createDisableUserSchema = (dataProvider: AdminDataProvider) =>
  z
    .object({
      id: z.string().optional(),
      internalId: z.string().optional(),
      firstName: z.string().optional(),
      lastName: z.string().optional(),
    })
    .superRefine(async (data, ctx) => {
      if (!(data.id || data.internalId)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Either 'id' or 'internalId' must be provided.",
          path: ['id'],
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Either 'id' or 'internalId' must be provided.",
          path: ['internalId'],
        });

        return;
      }

      if (data.id) {
        try {
          const res = await dataProvider.getOne('users', { id: data.id });
          if (!res.data) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "User with this id doesn't exist.",
              path: ['id'],
            });
          } else {
            data.internalId = res.data.internalId;
            data.firstName = res.data.firstName;
            data.lastName = res.data.lastName;
          }
        } catch {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "User with this id doesn't exist.",
            path: ['id'],
          });
        }
      }

      if (data.internalId) {
        const res = await dataProvider.getUserByInternalId(data.internalId);
        if (!res) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "User with this internal id doesn't exist.",
            path: ['internalId'],
          });
        } else {
          data.id = res.id.toString();
          data.firstName = res.firstName;
          data.lastName = res.lastName;
        }
      }
    });
