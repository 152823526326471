import { DataProvider, PaginationPayload, SortPayload } from 'react-admin';
import { ResourcesValue } from '../components/admin/AdminResources';
import { fetchCurrentUser } from './customEndpoints/fetchCurrentUser';
import { fetchFeatureFlags } from './customEndpoints/fetchFeatureFlags';
import { getPostsChartData } from './customEndpoints/fetchPostsChartData';
import { getPulseQuestionsChartData } from './customEndpoints/fetchPulseQuestionsChartData';
import { fetchSendBirdAppId } from './customEndpoints/fetchSendBirdAppId';
import { fetchUserRole } from './customEndpoints/fetchUserRole';
import { getUsersChartData } from './customEndpoints/fetchUsersChartData';
import { resendWelcomeSms } from './customEndpoints/resendWelcomeSms';
import { setUsersEnabled } from './customEndpoints/setUsersEnabled';
import { uploadFile } from './customEndpoints/uploadFile';
import { uploadUsers } from './customEndpoints/uploadUsers';
import { getBadgesAwardsChartData } from './hooks/useBadgesAwardsChartData';
import { HttpClient } from './httpClient';
import { getUserByPhoneNumber } from './customEndpoints/getUserByPhoneNumber';
import { postUser } from './customEndpoints/postUser';
import { fetchAllUsers } from './customEndpoints/fetchAllUsers';
import { fetchSelectList } from './hooks/useFetchSelectList';
import { fetchAllTopics } from './hooks/useFetchAllTopics';
import { getUserByInternalId } from './customEndpoints/getUserByInternalId';
import {
  addCohortMembers,
  removeCohortMembers,
} from './customEndpoints/updateCohortMembers';
import { getUsersWidgetsData } from './customEndpoints/fetchUsersWidgetsData';
import { getFeedWidgetsData } from './customEndpoints/fetchFeedWidgetsData';
import { setUserEnabled } from './customEndpoints/setUserEnabled';

export type AdminDataProvider = ReturnType<typeof createAdminDataProvider>;

const resourcePaths: { [key in ResourcesValue]?: string } = {
  [ResourcesValue.POSTS]: 'posts',
};

const getResourcePath = (resource: ResourcesValue) => {
  if (resourcePaths[resource]) {
    return `/${resourcePaths[resource]}`;
  } else {
    return `/admin/${resource}`;
  }
};

export const createAdminDataProvider = (httpClient: HttpClient) =>
  ({
    getList: async (resource, params) => {
      const { page, perPage } = params.pagination as PaginationPayload;
      const { field, order } = params.sort as SortPayload;
      const query = new URLSearchParams({
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      });

      const path = `${getResourcePath(resource as ResourcesValue)}?${query}`;

      const response = await httpClient.api(path);

      return {
        data: response.json.data,
        total: response.json.total,
      };
    },
    getOne: async (resource, params) => {
      const path = `/admin/${resource}/${params.id}`;

      const response = await httpClient.api(path);

      return {
        data: response.json,
      };
    },
    getMany: async (resource, params) => {
      const idField = params.meta?.idField ?? 'id';

      const query = new URLSearchParams({
        filter: JSON.stringify({ [idField]: params.ids }),
      });

      const path = `/admin/${resource}?${query}`;

      const response = await httpClient.api(path);

      return {
        data: response.json.data.map((d: any) => ({
          id: JSON.stringify(d),
          ...d,
        })),
      };
    },
    // TODO: review this
    getManyReference: async (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = new URLSearchParams({
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      });

      const path = `/admin/${resource}?${query}`;

      const response = await httpClient.api(path);

      return {
        data: response.json.data,
        total: response.json,
      };
    },
    update: async (resource, params) => {
      const path = `/admin/${resource}/${params.id}`;

      const response = await httpClient.api(path, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      });

      return {
        data: response.json,
      };
    },
    updateMany: async (resource, params) => {
      const query = new URLSearchParams({
        filter: JSON.stringify({ id: params.ids }),
      });

      const path = `/admin/${resource}?${query}`;

      const response = await httpClient.api(path, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      });

      return {
        data: response.json,
      };
    },
    create: async (resource, params) => {
      const path = getResourcePath(resource as ResourcesValue);

      const response = await httpClient.api(path, {
        method: 'POST',
        body: JSON.stringify(params.data),
      });

      return {
        data: response.json,
      };
    },
    delete: async (resource, params) => {
      const path = `/admin/${resource}/${params.id}`;

      const response = await httpClient.api(path, {
        method: 'DELETE',
      });

      return {
        data: response.json,
      };
    },
    deleteMany: async (resource, params) => {
      const query = new URLSearchParams({
        filter: JSON.stringify({ id: params.ids }),
      });

      const path = `/admin/${resource}?${query}`;

      await httpClient.api(path, {
        method: 'DELETE',
      });

      return {
        data: params.ids,
      };
    },
    uploadUsers: uploadUsers(httpClient),
    postUser: postUser(httpClient),
    getUserByPhoneNumber: getUserByPhoneNumber(httpClient),
    getUserByInternalId: getUserByInternalId(httpClient),
    setUsersEnabled: setUsersEnabled(httpClient),
    setUserEnabled: setUserEnabled(httpClient),
    resendWelcomeSms: resendWelcomeSms(httpClient),
    fetchFeatureFlags: fetchFeatureFlags(httpClient),
    fetchUserRole: fetchUserRole(httpClient),
    fetchCurrentUser: fetchCurrentUser(httpClient),
    fetchAllUsers: fetchAllUsers(httpClient),
    fetchSendBirdAppId: fetchSendBirdAppId(httpClient),
    uploadFile: uploadFile(httpClient),
    getPostsChartData: getPostsChartData(httpClient),
    getFeedWidgetsData: getFeedWidgetsData(httpClient),
    getUsersChartData: getUsersChartData(httpClient),
    getUsersWidgetsData: getUsersWidgetsData(httpClient),
    getPulseQuestionsChartData: getPulseQuestionsChartData(httpClient),
    getBadgesAwardsChartData: getBadgesAwardsChartData(httpClient),
    fetchSelectList: fetchSelectList(httpClient),
    fetchAllTopics: fetchAllTopics(httpClient),
    addCohortMembers: addCohortMembers(httpClient),
    removeCohortMembers: removeCohortMembers(httpClient),
  }) satisfies DataProvider;
