import React, { useState } from 'react';
import { Card, Box, Grid } from '@mui/material';
import { useUsersChartData } from '../../../../api/hooks/useUsersChartData';
import { UsersWidgets } from './Widgets';
import { UsersChart } from './Chart';
import { DateRangeFilter } from '../DateRangeFilter';

export const UsersAnalytics: React.FC = () => {
  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);

  const [after, setAfter] = useState(thirtyDaysAgo.toISOString().split('T')[0]);
  const [before, setBefore] = useState(now.toISOString().split('T')[0]);

  const { data, refetch } = useUsersChartData({
    after: new Date(after).toISOString(),
    before: new Date(before).toISOString(),
  });

  return (
    <>
      <UsersWidgets />
      <Card sx={{ boxShadow: 3, padding: 2, marginBottom: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            marginBottom: 2,
          }}
        >
          <DateRangeFilter
            startDate={after}
            endDate={before}
            setStartDate={setAfter}
            setEndDate={setBefore}
            minDate="2025-01-10"
            onDateChange={() => void refetch()}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <UsersChart
              title="Engaged Users"
              chartData={data?.engaged || []}
              dataKey="count"
              lineName="No. of engaged users"
              includePercentage={true}
              percentageLineName="% out of activated"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UsersChart
              title="Activated Users"
              chartData={data?.activated || []}
              dataKey="count"
              lineName="No. of activated users"
              includePercentage={true}
              percentageLineName="% out of onboarded"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UsersChart
              title="Onboarded Users"
              chartData={data?.onboarded || []}
              dataKey="count"
              lineName="No. of onboarded users"
              includePercentage={false}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
