import React, { useState } from 'react';
import { FeedWidgets } from './Widgets';
import { FeedChart } from './Chart';

export const FeedAnalytics: React.FC = () => {
  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);

  const [after, setAfter] = useState(thirtyDaysAgo.toISOString().split('T')[0]);
  const [before, setBefore] = useState(now.toISOString().split('T')[0]);

  return (
    <>
      <FeedWidgets after={after} before={before} />
      <FeedChart
        after={after}
        before={before}
        setAfter={setAfter}
        setBefore={setBefore}
      />
    </>
  );
};
