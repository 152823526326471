import en from 'ra-language-english';
import { i18n } from 'react-admin-import-csv';
import { Translations } from './Translations';

export const englishTranslations: Translations = {
  ...en,
  ra: {
    ...en.ra,
    message: {
      ...en.ra.message,
      delete_title: 'Delete',
      delete_content:
        'Are you sure you want to delete this item? This action cannot be undone.',
      bulk_delete_title: 'Delete item |||| Delete %{smart_count} items',
      bulk_delete_content:
        'Are you sure you want to delete this item? This action cannot be undone |||| Are you sure you want to delete these %{smart_count} items? This action cannot be undone',
    },
  },
  csv: {
    ...i18n.en.csv,
  },
  resources: {
    users: {
      name: 'User |||| Users',
      fields: {
        pictureUrl: 'Profile picture',
        disabledFrom: 'Disabled on',
        jobTitleName: 'Job title',
        departmentName: 'Department',
        regionName: 'Region',
        unitIds: 'Units',
        signInMethod: 'Log in',
      },
    },
    departments: {
      name: 'Department |||| Departments',
      fields: {
        name: 'Name',
      },
    },
    jobTitles: {
      name: 'Job Title |||| Job Titles',
      fields: {
        name: 'Name',
      },
    },
    regions: {
      name: 'Region |||| Regions',
      fields: {
        name: 'Name',
      },
    },
    units: {
      name: 'Unit |||| Units',
      fields: {
        name: 'Name',
      },
    },
    topics: {
      name: 'Topic |||| Topics',
      fields: {
        formTypeId: 'Form type',
        displayName: 'Name',
      },
    },
    nudges: {
      name: 'Nudge |||| Nudges',
      fields: {
        title: 'Title',
        description: 'Description',
        hintDescription: 'Hint description',
        hintValidTillDateTime: 'Hint valid until',
        duration: 'Duration',
        ['image.src']: 'Image',
        nudgeSlides: 'Slides',
        nudgeFeedbacks: 'Feedback',
        nudgeCategories: 'Categories',
      },
    },
    nudgeCategories: {
      name: 'Nudge Category |||| Nudge Categories',
      fields: {
        name: 'Name',
      },
    },
    nudgeSlides: {
      name: 'Nudge Slide |||| Nudge Slides',
      fields: {
        content: 'Content',
        ['image.src']: 'Image',
      },
    },
    nudgeFeedbacks: {
      name: 'Nudge Feedback',
      fields: {
        rating: 'Rating',
        isInteresting: 'Is interesting',
      },
    },
    cohorts: {
      name: 'Cohort |||| Cohorts',
      fields: {
        name: 'Name',
      },
    },
    cohortMemberships: {
      name: 'Cohort Members',
    },
  },
  moonstar: {
    appName: 'Moonstar Admin Portal',
    login: {
      welcome:
        'Welcome to Moonstar Admin Portal, where you can manage your employee data. To log in, use the same details that you use to log in to the Moonstar Communication App.',
      email: 'Email',
      phone: 'Phone number',
    },
    import: {
      label: 'Import',
      success: 'Uploaded all items successfully',
      partial_success:
        'Uploaded some items. %{total_failed} had errors and failed to import',
      error: 'Uploading failed. No items were imported',
    },
    bulk_disable: {
      label: 'Bulk Disable',
      success: 'All users disabled successfully',
      partial_success:
        'Disabled some of the users. %{total_failed} had errors and failed.',
      error: 'Disabling failed. No items were imported',
    },
    sms_message: {
      resend: 'Resend welcome SMS',
      success: '%{smart_count} messages enqueued for sending',
      partial_success:
        'Enqueued %{total_success} messages, %{total_failed} had errors and failed to enqueue',
      error: 'Enqueuing messages failed. No messages were enqueued',
    },
    bulk_update: {
      success: '%{smart_count} items updated',
      partial_success:
        'Updated %{total_success} items. %{total_failed} had errors and failed to update',
      error: 'Updating items failed. No items were updated',
    },
    post: {
      preview: {
        recognition: {
          awarded: 'awarded',
          badge: 'a badge',
        },
      },
    },
    user: {
      edit: {
        section: {
          personalDetails: 'Personal details',
          companyRelatedInfo: 'Company related info',
          profileSettings: 'Profile settings',
        },
        phoneNumber: {
          requiredText:
            'Phone number is required, because the user cannot sign in with their email',
          enabledHelperText: 'Sign in with phone number is enabled',
          disabledHelperText: 'Sign in with phone number is disabled',
        },
        email: {
          enabledHelperText: 'Sign in with email is enabled',
          disabledHelperText: 'Sign in with email is disabled',
        },
        signIn: {
          notMatchingPart1: 'Sign in with',
          notMatchingPart2: 'is enabled',
        },
      },
      filter: {
        userIsDisabled: {
          label: 'View',
          nullLabel: 'All',
          trueLabel: 'Disabled',
          falseLabel: 'Enabled',
        },
      },
      disable: {
        disable: 'Disable',
        enable: 'Enable',
      },
      exportTemplate: {
        button: 'Template',
        tooltip: 'Download import template',
      },
    },
    cohortMemberships: {
      add: {
        successMessage: '%{smart_count} members added',
      },
      remove: {
        successMessage: '%{smart_count} members removed',
      },
    },
    validation: {
      phoneNumber:
        'Not a valid phone number. Must be in format: +[countryCode][number]. E.g. +40712345678',
      maxFileSize: 'File size must be less than %{maxSize}',
    },
  },
  chat: {
    close: 'Close',
    create: 'Create',
    reply: 'Reply',
    forward: 'Forward',
    pin: 'Pin',
    unpin: 'Unpin',
    delete: 'Delete',
    kick: 'Kick',
    somethingWrong: 'Something went wrong. Please try again later.',
    pinMessage:
      'Message has been pinned. You can pin up to %{maxPinnedMessages} messages. You can find all of your pinned messages in the pinned tab in the group settings.',
    unpinMessage:
      'Message has been unpinned. You can find all of your pinned messages in the pinned tab in the group settings.',
    pinMessageMax:
      'You can pin up to %{maxPinnedMessages} messages. Unpin some messages',
    messageForwarded: 'Message forwarded successfully',
    messageForwardTitle: 'Group(s) you want to share the message with',
    cancel: 'Cancel',
    somethingWentWrong: 'Something went wrong. Please try again later.',
    groupCreationError:
      "Couldn't create a group, please try again later or contact Moonstar support.",
    groupCreateSubtitle:
      'Create a chat that allows one-to-one communication or a group of up to 100 members.',
    supergroupCreateSubtitle:
      'Create supergroup that allows chat for up to 20,000 members.',
    badUserRole:
      'Unknown user role, please contact your administrator or Moonstar support.',
    searchNewGroup: 'Search for members for the new group',
    searchNewSupergroup: 'Search for members for the new supergroup',
    pinnedMessagesCouldNotBeFetched:
      "We couldn't get your pinned messages. Please try again later.",
    pinnedMessagesNotFound: 'You have no pinned messages in this group yet.',
    createGroupsModalTitle: 'Select members to create a group with',
    inviteUsersModalTitle: 'Select members to invite',
    removeUserError:
      'Failed to remove user from the group. Please try again later.',
    createNewGroup: 'Create a new group',
    discardChannelModalTitle: 'Discard channel',
    discaradChannelModalContent:
      'If you leave this channel without sending a message, the channel will be discarded. Are you sure you want to leave and discard the channel?',
    discard: 'Discard',
    stay: 'Stay',
  },
};
