import { useState, ChangeEvent, FC, useCallback, useRef } from 'react';
import { Button, useDataProvider, useNotify, useTranslate } from 'react-admin';
import DataGrid, { Column, RenderEditCellProps } from 'react-data-grid';
import {
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { AdminDataProvider } from '../../../api/adminDataProvider';
import { useMutation } from '@tanstack/react-query';
import Papa from 'papaparse';

import {
  UserCsvExportRow,
  UserCsvResultRow,
  UserCsvRow,
  UserCsvValidationError,
  userSchema,
} from './types';
import 'react-data-grid/lib/styles.css';
import './ImportUsersButton.css';
import { saveAs } from '../../../utils/saveAs';

async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const ImportUsersButton: FC = () => {
  const [validationStatus, setValidationStatus] = useState<
    'idle' | 'validating' | 'done'
  >('idle');
  const translate = useTranslate();
  const label = translate('csv.buttonMain.tooltip');
  const importRef = useRef<HTMLInputElement>(null);
  const dataProvider = useDataProvider<AdminDataProvider>();
  const notify = useNotify();

  const [csvData, setCsvData] = useState<UserCsvResultRow[]>([]);
  const [validationErrors, setValidationErrors] = useState<
    UserCsvValidationError[][]
  >([]);
  const [open, setOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState({
    successCount: 0,
    errorCount: 0,
    total: 0,
    unprocessed: [] as UserCsvExportRow[],
  });

  const validatePhoneNumberExists = async (phoneNumber: string) => {
    const res = await dataProvider.getUserByPhoneNumber(phoneNumber);
    if (res) {
      return `This phone number is already taken by user: ${res.firstName} ${res.lastName}`;
    }
  };

  const validateInternalIdExists = async (internalId: string) => {
    const res = await dataProvider.getUserByInternalId(internalId);
    if (res) {
      return `This internal identifier is already taken by user: ${res.firstName} ${res.lastName}`;
    }
  };

  const handleCellEdit = async (
    rowIdx: number,
    columnKey: keyof UserCsvRow,
    newValue: string
  ) => {
    setValidationStatus('validating');

    const updatedRow = { ...csvData[rowIdx], [columnKey]: newValue };
    const rows = [...csvData];
    rows[rowIdx] = updatedRow;
    setCsvData(rows);

    const rowErrors = await validateRow(updatedRow, rows);

    const newValidationErrors = { ...validationErrors };
    if (rowErrors.length > 0) {
      newValidationErrors[rowIdx] = rowErrors;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newValidationErrors[rowIdx];
    }

    setValidationErrors(newValidationErrors);
    setValidationStatus('done');
  };

  const autoFocusAndSelect = (input: HTMLInputElement | null) => {
    input?.focus();
    input?.select();
  };

  const TextEditor = <TRow, TSummaryRow>({
    row,
    column,
    onRowChange,
    onClose,
    rowIdx,
  }: RenderEditCellProps<TRow, TSummaryRow> & { rowIdx: number }) => {
    const [localValue, setLocalValue] = useState<string>(
      row[column.key as keyof TRow] as unknown as string
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocalValue(event.target.value);
    };

    const handleBlur = async () => {
      onRowChange({ ...row, [column.key]: localValue });
      await handleCellEdit(rowIdx, column.key as keyof UserCsvRow, localValue);
      onClose(true, false);
    };

    const handleKeyDown = async (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === 'Enter') {
        onRowChange({ ...row, [column.key]: localValue });
        await handleCellEdit(
          rowIdx,
          column.key as keyof UserCsvRow,
          localValue
        );
        onClose(true, false);
      } else if (event.key === 'Escape') {
        onClose(false, false);
      }
    };

    return (
      <input
        className="textEditor rdg-cell"
        ref={autoFocusAndSelect}
        value={localValue}
        onChange={handleChange}
        onBlur={() => void handleBlur()}
        onKeyDown={(e) => void handleKeyDown(e)}
      />
    );
  };

  const columns: readonly Column<UserCsvResultRow>[] = [
    {
      key: 'rowNumber',
      name: '',
      width: 40,
      renderCell: (props) => <div>{props.rowIdx + 1}</div>, // rowIdx is 0-based, so add 1 for display
    },
    {
      key: 'internalId',
      name: 'Internal ID',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'internalId', rowIdx),
    },
    {
      key: 'firstName',
      name: 'First name',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'firstName', rowIdx),
    },
    {
      key: 'lastName',
      name: 'Last name',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'lastName', rowIdx),
    },
    {
      key: 'phoneNumber',
      name: 'Phone number',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'phoneNumber', rowIdx),
    },
    {
      key: 'email',
      name: 'Email',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'email', rowIdx),
    },
    {
      key: 'startDate',
      name: 'Start date',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'startDate', rowIdx),
    },
    {
      key: 'languageCountryCode',
      name: 'Language',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'languageCountryCode', rowIdx),
    },
    {
      key: 'department',
      name: 'Department',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'department', rowIdx),
    },
    {
      key: 'jobTitle',
      name: 'Job title',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'jobTitle', rowIdx),
    },
    {
      key: 'region',
      name: 'Region',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'region', rowIdx),
    },
    {
      key: 'unit',
      name: 'Unit',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'unit', rowIdx),
    },
  ];

  const onClickImport = useCallback(() => {
    if (!importRef.current) return;
    importRef.current.value = '';
    importRef.current.click();
  }, []);

  const parseRow = async (user: UserCsvRow) => {
    const parseResult = userSchema.safeParse(user);

    const errors =
      parseResult.error?.errors.map((error) => ({
        property: error.path.toString(),
        message: error.message,
      })) ?? [];

    if (!errors.some((msg) => msg.property === 'phoneNumber')) {
      const res = await validatePhoneNumberExists(user.phoneNumber);

      if (res) {
        errors.push({
          property: 'phoneNumber',
          message: res,
        });
      }
    }

    if (user.internalId && user.internalId.length) {
      const res = await validateInternalIdExists(user.internalId);

      if (res) {
        errors.push({
          property: 'internalId',
          message: res,
        });
      }
    }

    return errors;
  };

  const validateRow = async (user: UserCsvRow, users: UserCsvRow[]) => {
    setValidationStatus('validating');
    const errors = await parseRow(user);

    const duplicateErrors: UserCsvValidationError[] = [];

    const duplicateInternalIdIndexes = users
      .map((u, index) => (u.internalId === user.internalId ? index + 1 : -1))
      .filter((index) => index !== -1);
    if (duplicateInternalIdIndexes.length > 1) {
      duplicateErrors.push({
        property: 'internalId',
        message: `Duplicate internal ID found in the import file at rows: ${duplicateInternalIdIndexes.join(
          ', '
        )}`,
      });
    }

    const duplicatePhoneNumberIndexes = users
      .map((u, index) => (u.phoneNumber === user.phoneNumber ? index + 1 : -1))
      .filter((index) => index !== -1);
    if (duplicatePhoneNumberIndexes.length > 1) {
      duplicateErrors.push({
        property: 'phoneNumber',
        message: `Duplicate phone number found in the import file at rows: ${duplicatePhoneNumberIndexes.join(
          ', '
        )}`,
      });
    }

    errors.push(...duplicateErrors);
    setValidationStatus('done');

    return errors;
  };

  const parseCsv = (file: File): Promise<UserCsvResultRow[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse<UserCsvRow>(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.trim(),
        transform: (value) => value.trim(),
        complete: (results) => {
          const filteredData = results.data.filter((row) =>
            Object.values(row).some(
              (value: string | null) => value !== '' && value !== null
            )
          );
          resolve(filteredData.map((row) => ({ ...row, status: 'pending' })));
        },
        error: (error) => reject(error),
      });
    });
  };

  const validateAllRows = async (jsonData: UserCsvRow[]) => {
    setValidationStatus('validating');
    const validationPromises = jsonData.map(async (user, index) => {
      const validationErrors = await validateRow(user, jsonData);
      return validationErrors.length > 0
        ? { index, errors: validationErrors }
        : null;
    });

    const validationResults = await Promise.all(validationPromises);

    const errors: UserCsvValidationError[][] = [];
    validationResults.forEach((result) => {
      if (result) {
        errors[result.index] = result.errors;
      }
    });

    setValidationStatus('done');
    return errors;
  };

  const importUsers = useMutation({
    mutationFn: async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      setCsvData([]);
      setValidationErrors([]);
      setShowSummary(false);
      setProcessing(false);
      setProgress({
        successCount: 0,
        errorCount: 0,
        total: 0,
        unprocessed: [],
      });

      try {
        const jsonData: UserCsvResultRow[] = await parseCsv(file);
        const errors = await validateAllRows(jsonData);

        setValidationErrors(errors);
        setCsvData(jsonData);
        setOpen(true);
      } catch (error) {
        console.error('Error processing CSV file:', error);
        throw error;
      }
    },
    onError: () => {
      notify(
        <Alert severity="error">{translate('moonstar.import.error')}</Alert>,
        {
          autoHideDuration: 5000,
        }
      );
    },
  });

  const handleUpload = async () => {
    setProcessing(true);
    setShowSummary(true);
    let successCount = 0;
    let errorCount = 0;

    const rows = csvData;
    const unprocessedRows = progress.unprocessed;

    for (let i = 0; i < rows.length; i++) {
      rows[i].status = 'processing';
      setCsvData([...rows]);
      try {
        const response = await dataProvider.postUser(rows[i]);
        if (response.status === 200) {
          successCount++;
          rows[i].status = 'success';
        } else {
          errorCount++;
          rows[i].status = 'error';
          unprocessedRows.push({
            ...rows[i],
            errorMessage: response.json,
          } as UserCsvExportRow);
        }
      } catch (error) {
        console.error(error, rows[i]);
        errorCount++;
        rows[i].status = 'error';
        unprocessedRows.push({
          ...rows[i],
          errorMessage:
            'Unexpected error. Please reach out to Moonstar Support.',
        } as UserCsvExportRow);
      }
      setCsvData([...rows]);
      setProgress({
        successCount,
        errorCount,
        total: csvData.length,
        unprocessed: unprocessedRows,
      });
      // due too clerk's 20requests/10s limit
      await sleep(500);
    }

    setProcessing(false);
    if (errorCount === 0) {
      notify(
        <Alert severity="success">{translate('moonstar.import.success')}</Alert>
      );
    } else {
      notify(
        <Alert severity="error">
          {translate('moonstar.import.partial_success', {
            total_failed: errorCount,
          })}
        </Alert>
      );
    }
  };

  const handleExport = () => {
    const result = Papa.unparse(progress.unprocessed, {
      delimiter: ',',
      quotes: false,
      skipEmptyLines: true,
    });

    saveAs(result, 'import-errors.csv', 'text/csv');
  };

  const renderCellWithTooltip = (
    row: UserCsvRow,
    columnKey: keyof UserCsvRow,
    rowIdx: number
  ) => {
    const errors = validationErrors[rowIdx] as
      | UserCsvValidationError[]
      | undefined;
    const errorMessage = errors?.find((error) => error.property === columnKey)
      ?.message;

    return errorMessage ? (
      <Tooltip title={errorMessage} placement="top" arrow>
        <div className="rdg-cell-error">{row[columnKey] || ''}</div>
      </Tooltip>
    ) : (
      <div>{row[columnKey] || ''}</div>
    );
  };

  const getValidationStatusText = () => {
    switch (validationStatus) {
      case 'validating':
        return 'Validating rows...';
      case 'done':
        return '';
      default:
        return '';
    }
  };

  return (
    <>
      <Tooltip title={label}>
        <div>
          <Button
            label="moonstar.import.label"
            onClick={onClickImport}
            disabled={importUsers.isPending}
          >
            <GetAppIcon
              style={{ transform: 'rotate(180deg)', fontSize: '20' }}
            />
          </Button>
          <input
            ref={importRef}
            type="file"
            style={{ display: 'none' }}
            onChange={importUsers.mutate}
            accept=".csv,.tsv"
          />
        </div>
      </Tooltip>

      <Dialog
        open={open}
        onClose={!processing ? () => setOpen(false) : undefined}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Import users
          {validationStatus === 'validating' && (
            <Box
              component="span"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="subtitle2">
                {getValidationStatusText()}
              </Typography>
              <CircularProgress size={20} />
            </Box>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="onboarding-grid-wrapper">
            <DataGrid
              columns={columns}
              rows={csvData}
              onRowsChange={setCsvData}
              defaultColumnOptions={{ resizable: true }}
              rowClass={(row) => {
                switch (row.status) {
                  case 'processing':
                    return 'row-processing';
                  case 'success':
                    return 'row-success';
                  case 'error':
                    return 'row-error';
                  default:
                    return '';
                }
              }}
            />
          </div>
          <div className="progress-summary">
            {showSummary ? (
              processing ? (
                <div>
                  <p>
                    Processing: {progress.successCount + progress.errorCount}/
                    {progress.total}
                  </p>
                </div>
              ) : (
                <p>
                  Onboarded {progress.successCount} users, {progress.errorCount}{' '}
                  errors
                </p>
              )
            ) : (
              <></>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ marginBottom: 2 }}>
          <Button
            onClick={handleExport}
            color="primary"
            disabled={processing || progress.unprocessed.length === 0}
          >
            <>Export unprocessed</>
          </Button>
          <Button
            onClick={() => void handleUpload()}
            color="primary"
            disabled={
              showSummary ||
              csvData.length === 0 ||
              Object.keys(validationErrors).length > 0
            }
          >
            <>Proceed</>
          </Button>
          <Button
            onClick={() => setOpen(false)}
            color="secondary"
            disabled={processing}
          >
            <>Close</>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
