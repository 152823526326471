import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  styled,
} from '@mui/material';
import { usePulseQuestionsChartData } from '../../../../api/hooks/usePulseQuestionsChartData';
import { sortBy } from '../../../../utils/sortBy';
import { useState } from 'react';
import { DateRangeFilter } from '../DateRangeFilter';

export const PulseChecksChart = () => {
  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);

  const [after, setAfter] = useState(thirtyDaysAgo.toISOString().split('T')[0]);
  const [before, setBefore] = useState(now.toISOString().split('T')[0]);

  const {
    data: questions,
    refetch,
    isFetching,
  } = usePulseQuestionsChartData({
    after: new Date(after).toISOString(),
    before: new Date(before).toISOString(),
  });

  const data = questions?.map((q) => {
    const positive = q.answers.filter(
      (a) => a.type === AnswerType.Agree || a.type === AnswerType.StronglyAgree
    );

    const negative = q.answers.filter(
      (a) =>
        a.type === AnswerType.Disagree || a.type === AnswerType.StronglyDisagree
    );

    const neutral = q.answers.filter((a) => a.type === AnswerType.Undecided);

    const answers = [
      {
        type: AnswerType.StronglyAgree,
        count: positive.reduce((sum, answer) => sum + answer.count, 0),
      },
      {
        type: AnswerType.StronglyDisagree,
        count: negative.reduce((sum, answer) => sum + answer.count, 0),
      },
      {
        type: AnswerType.Undecided,
        count: neutral[0]?.count || 0,
      },
    ];

    return {
      id: q.id,
      text: q.text,
      total: q.total,
      answers: sortBy(
        answers.filter((a) => a.count > 0),
        (x) => x.type,
        'descending'
      ),
    };
  });

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardHeader
        title="Pulse questions"
        action={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <Legend />
            <DateRangeFilter
              startDate={after}
              endDate={before}
              setStartDate={setAfter}
              setEndDate={setBefore}
              onDateChange={() => void refetch()}
            />
          </Box>
        }
      />
      <CardContent>
        <TableContainer>
          <Table aria-label="engagement table">
            <TableHead>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell>Answers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Typography variant="body2">Fetching data...</Typography>
                  </TableCell>
                </TableRow>
              ) : data?.length ? (
                data.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.text}</TableCell>
                    <TableCell style={{ width: '50%' }}>
                      <Box display="flex" flexDirection="column">
                        <StackedBar
                          items={item.answers.map((a) => ({
                            type: a.type,
                            percentage:
                              Math.round((a.count / item.total) * 1000) / 10,
                          }))}
                          colors={answerColors}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <Typography variant="body2">
                      No data available for the selected range.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

const AnswerType = {
  StronglyDisagree: 1,
  Disagree: 2,
  Undecided: 3,
  Agree: 4,
  StronglyAgree: 5,
} as const;

const answerColors: Record<
  (typeof AnswerType)[keyof typeof AnswerType],
  string
> = {
  [AnswerType.StronglyDisagree]: '#d16b77',
  [AnswerType.Disagree]: '#f7b7bd',
  [AnswerType.Undecided]: '#EBE9ED',
  [AnswerType.Agree]: '#93D4BF',
  [AnswerType.StronglyAgree]: '#28808F',
};

const AnswerBox = styled(Box)(() => ({
  height: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PercentageText = styled(Typography)(() => ({
  color: 'white',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
}));

const StackedBar = (props: {
  items: { type: number; percentage: number }[];
  colors: { [key: number]: string };
}) => {
  return (
    <Box
      display="flex"
      height={30}
      width="100%"
      borderRadius={2}
      overflow="hidden"
    >
      {props.items.map((item, index) => (
        <AnswerBox
          key={index}
          width={`${item.percentage}%`}
          bgcolor={props.colors[item.type]}
        >
          <PercentageText variant="body2">{item.percentage}%</PercentageText>
        </AnswerBox>
      ))}
    </Box>
  );
};

const Legend = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      padding: 1,
      borderRadius: 2,
    }}
  >
    <Box display="flex" alignItems="center" mr={2}>
      <Box
        width={20}
        height={20}
        bgcolor={answerColors[AnswerType.StronglyAgree]}
        mr={1}
      />
      <Typography variant="body2">Agree</Typography>
    </Box>
    <Box display="flex" alignItems="center" mr={2}>
      <Box
        width={20}
        height={20}
        bgcolor={answerColors[AnswerType.Undecided]}
        mr={1}
      />
      <Typography variant="body2">Undecided</Typography>
    </Box>
    <Box display="flex" alignItems="center">
      <Box
        width={20}
        height={20}
        bgcolor={answerColors[AnswerType.StronglyDisagree]}
        mr={1}
      />
      <Typography variant="body2">Disagree</Typography>
    </Box>
  </Box>
);
